<template>
  <form class="login" @submit.prevent="submit">
    <div class="row">
      <div class="col-lg-12 col-xs-12 xs-m-0 m-10px" style="font-weight: 600;">
        <div v-if="failed" style="color: #f30000;">{{ failMessage }}</div>
        <div v-if="succeed" style="color: green;">Your profile updated successfully</div>
      </div>
      <template v-for="(field, i) in fields">
        <FormField :error="getError(field.name)" class="col-lg-12 col-xs-12 xs-m-0 m-10px" :key="i">
          <template v-slot>
            <span>{{ field.label }}</span>
            <input
              class="mt-1"
              :required="field.required"
              v-model="form[field.name]"
              :type="field.type"
              :placeholder="field.placeholder"
              @input="() => onInput(field.name)"
            />
          </template>
        </FormField>
      </template>
      <div class="col-lg-4 col-xs-12 m-10px xs-m-0">
        <div class="m-r-1 rounded-5">
          <button class="text-medium medium-font button text-color-white bg-light-green" type="submit">
            Save
          </button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import FormField from 'Components/FormField';

const fields = [
  {
    label: 'Your name',
    placeholder: 'Name',
    type: 'text',
    name: 'name',
    required: true,
    validator: value => {
      const len = 2;
      if (!value || value.length < len) {
        throw new Error(`Enter at least ${len} symbols`);
      }
      return true;
    },
  },
  {
    label: 'Your phone number',
    placeholder: 'Phone',
    type: 'tel',
    name: 'phone',
    required: true,
    validator: value => Boolean(value),
  },
  {
    label: 'Company name',
    placeholder: 'Company',
    type: 'text',
    name: 'company',
    required: true,
    validator: value => {
      const len = 3;
      if (!value || value.length < len) {
        throw new Error(`Enter at least ${len} symbols`);
      }
      return true;
    },
  },
];

export default {
  name: 'Profile',
  components: { FormField },
  methods: {
    submit() {
      this.$api
        .updateProfile(this.form)
        .then(data => {
          this.$store.dispatch('setUser', data);
          this.succeed = true;
          this.failed = false;
        })
        .catch(err => {
          this.succeed = false;
          this.failed = true;
          this.failMessage = err.message;
        });
    },
    onInput(name) {
      this.errors = this.errors.filter(({ id }) => id !== name);
    },
    getError(name) {
      return (this.errors.find(({ id }) => id === name) || {}).message;
    },
    setUserData(data) {
      const { name, phone, company } = data || this.userData;
      this.form = { name, phone, company };
    },
  },
  data() {
    return {
      fields,
      form: {},
      errors: [],
      failed: false,
      succeed: false,
      failMessage: '',
    };
  },
  computed: {
    userData() {
      return this.$store.getters.userData;
    },
  },
  watch: {
    userData: {
      deep: true,
      handler() {
        this.setUserData(this.userData);
      },
    },
  },
  created() {
    this.subUserData = this.$store.subscribeAction(action => {
      if (action.type === 'setUser') {
        this.setUserData(action.payload);
      }
    });
    this.setUserData();
  },
  beforeDestroy() {
    this.subUserData();
  },
};
</script>
